import React, { useEffect } from 'react';
import './App.css'; // Stil dosyanızın adı ve uzantısına göre değiştirin

function App() {
  useEffect(() => {
    // Sayfa yüklendiğinde radigital.io'ya yönlendirme işlemi
    window.location.href = "https://radigital.io";
  }, []); 

  return (
<div class="spinner"></div>
  );
}

export default App;
